

@media screen and (min-width: 1030px) {
    .pricing-div {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-self: center;
        text-align: center;
        margin: 60px 0px 90px 10px;
    }
    
    .steps-content {
        min-height: 200px;
        margin-top: 16px;
        padding-top: 80px;
        text-align: center;
        background-color: #fafafa;
        border: 1px dashed #e9e9e9;
        border-radius: 2px;
        color: rgb(47, 47, 48);
    }
      
    .steps-action {
        margin-top: 24px;
    }
    
    .card-division {
        display: flex;
        width: 100%;
        justify-content: center;
    }
    
    .card-first-half {
        width: 40%;
    }
    
    .card-sec-half {
        width: 40%;
        margin-left: 10px;
    
    }
    
    .base-card {
        padding: 20px 30px !important;
        width: 100%;
    }
    
    .rad-grp {
        width: 100%;
    }
    
    .sign-up-form {
       text-align: left;
    }
    
    .pack-name {
        padding: 1rem;
        background-color: #45287217;
        margin: 5px 0px;
    }
    
    .primary-button {
        background: #452872;
        color: #fff;
        font-size: 16px;
        font-family: vagRund;
        border: none;
        border-radius: 4px;  
        padding: 8px 20px;
        display: block;
        width: 100%;
        letter-spacing: .5px;
        transition: .2s;
        cursor: pointer;
        margin: 20px;
    }
    
    .primary-button:hover,
      .primary-button:focus{
        background: #462872b4;
        color: #fff;
      }
      
    
    .steps-sect {
        margin: 15px 0 !important;
    }
    
    .step-payment {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
    }

   

    .header-line {
        color: rgb(47, 47, 48);
        font-size: 28px;
        font-family: vagRund;

    }

    .desc {
        color: grey;
        font-size: 16px;
        letter-spacing: 0.1px;
        font-family: vagRund;
        margin: 10px 0px;
    }

    .curr-dd {
        display: flex;
        justify-content: center;
        width: 100%;
        margin: 8px 10px 10px 10px;
    }
    .select-currency {
        color: grey;
        font-size: 16px;
        letter-spacing: 0.1px;
        font-family: vagRund;
    }

    .currency-button {
        width: 140px;
        border-radius: 36px !important; 
        border: 1px solid #452872 !important;
        height: 45px !important;
        background:#452872 !important;
        color: white !important;
    }
    .currency-button:hover {
        color: rgb(2, 2, 99) !important;
        border: 1px solid rgb(2, 2, 99) !important;
        border-radius: 36px !important; 
        height: 45px !important;


    }

    .currency-button:active {
        background:#452872;
        color: white;
        border: 1px solid rgb(2, 2, 99) !important;
        border-radius: 36px !important; 
        height: 45px !important;

    }

    .currency-primary {
        width: 140px;
        background:#7b56b3 !important;
        color: white !important;
        border: 1px solid rgb(2, 2, 99) !important;
        border-radius: 36px !important; 
        height: 45px !important;
        margin-right: 20px;

        
    }
}

@media screen and (min-width: 600px) and (max-width: 1030px) {
    .pricing-div {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-self: center;
        text-align: center;
    }
    
    .steps-content {
        min-height: 200px;
        margin-top: 16px;
        padding-top: 60px;
        text-align: center;
        background-color: #fafafa;
        border: 1px dashed #e9e9e9;
        border-radius: 2px;
        color: rgb(47, 47, 48);
    }
      
    .steps-action {
        margin-top: 24px;
    }
    
    .card-division {
        display: flex;
        width: 100%;
        justify-content: center;
    }
    
    .card-first-half {
        width: 50%;
    }
    
    .card-sec-half {
        width: 50%;
        margin-left: 10px;
    
    }
    
    .base-card {
        padding: 2px 2px !important;
        width: 100%;
    }
    
    .rad-grp {
        width: 100%;
    }
    
    .sign-up-form {
       text-align: left;
    }
    
    .pack-name {
        padding: 6px;
        background-color: #45287217;
        margin: 5px 0px;
    }
    
    .primary-button {
        background: #452872;
        color: #fff;
        font-size: 16px;
        font-family: vagRund;
        border: none;
        border-radius: 4px;  
        padding: 8px 10px;
        display: block;
        width: 100%;
        letter-spacing: .5px;
        transition: .2s;
        cursor: pointer;
        margin: 20px;
    }
    
    .primary-button:hover,
      .primary-button:focus{
        background: #462872ad;
        color: #fff;
      }
      
    
    .steps-sect {
        margin: 15px 0 !important;
    }
    
    .step-payment {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
    }

    .ant-steps-item-container {
        outline: none;
        width: 100% !important;
        display: grid !important;
    }
    .ant-card-body {
        padding: 4px !important;
    }

    .ant-form-item-label > label {
        position: relative;
        display: -ms-inline-flexbox;
        display: inline-flex;
        -ms-flex-align: center;
        align-items: center;
        height: 32px;
        color: rgb(47, 47, 48);
        font-size: 12px !important;
        font-weight: 400 !important;
    }
    .curr-dd {
        display: flex;
        justify-content: flex-end;
        width: 100%;
    }

    .header-line {
        color: rgb(47, 47, 48);
        font-size: 33px;
        font-family: vagRund;

    }

    .desc {
        color: grey;
        font-size: 16px;
        letter-spacing: 0.1px;
        font-family: vagRund;
        margin: 10px 0px;
    }

    .curr-dd {
        display: flex;
        justify-content: center;
        width: 100%;
        margin: 8px 10px 10px 10px;
    }
    .select-currency {
        color: grey;
        font-size: 16px;
        letter-spacing: 0.1px;
        font-family: vagRund;
    }

    .currency-button {
        width: 100px;
        border: 1px solid rgb(2, 2, 99) !important;
        border-radius: 36px !important; 
    }
    .currency-button:hover {
        color: rgb(2, 2, 99) !important;
        border: 1px solid rgb(2, 2, 99) !important;
        border-radius: 36px !important; 

    }

    .currency-button:active {
        background:#452872;
        color: white;
        border: 1px solid rgb(2, 2, 99) !important;
        border-radius: 36px !important; 
    }

    .currency-primary {
        width: 100px;
        background:#452872 !important;
        color: white !important;
        border: 1px solid rgb(2, 2, 99) !important;
        border-radius: 36px !important; 
        
    }

}

@media screen and (max-width: 600px) {
    .pricing-div {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-self: center;
        text-align: center;
        width: 100%;

    }
    
    .steps-content {
        min-height: 200px;
        margin-top: 16px;
        padding-top: 80px;
        text-align: center;
        background-color: #fafafa;
        border: 1px dashed #e9e9e9;
        border-radius: 2px;
        color: rgb(47, 47, 48);
        width: 100%;
    }
      
    .steps-action {
        margin-top: 24px;
    }
    
    .card-division {
        display: block;
        width: 100%;
        justify-content: center;
    }
    
    .card-first-half {
        width: 100%;
    }
    
    .card-sec-half {
        width: 100%;
        margin-left: 10px;
    
    }
    
    .base-card {
        padding: 2px 3px !important;
        width: 100%;
    }

    .ant-card-body {
        padding: 4px !important;
    }
    
    .rad-grp {
        width: 100%;
    }
    
    .sign-up-form {
       text-align: left;
    }
    
    .pack-name {
        padding: 5px;
        background-color: #45287217;
        margin: 5px 0px;
    }
    
    .primary-button {
        background: #452872;
        color: #fff;
        font-size: 16px;
        font-family: vagRund;
        border: none;
        border-radius: 4px;  
        padding: 4px 10px;
        display: block;
        width: 100%;
        letter-spacing: .5px;
        transition: .2s;
        cursor: pointer;
        margin: 20px;
    }
    
    .primary-button:hover,
      .primary-button:focus{
        background: #462872c2;
        color: #fff;
      }
      
    
    .steps-sect {
        margin: 15px 0 !important;
    }
    
    .step-payment {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
    }

    .registration-form {
        width: 100%;
    }

    .registration-main {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;
        align-items: center;
        padding: 4px;
    }

    .ant-table-wrapper {
        clear: both;
        width: 100% !important;
        overflow-x: auto !important;
    }

    .ant-steps-item-container {
        outline: none;
        width: 100% !important;
        display: grid !important;
    }

    .curr-dd {
        display: flex;
        justify-content: flex-end;
        width: 100%;
    }

    .header-line {
        color: rgb(47, 47, 48);
        font-size: 20px;
        font-family: vagRund;
        margin: 8px 0px;


    }

    .desc {
        color: grey;
        font-size: 10px;
        letter-spacing: 0.1px;
        font-family: vagRund;
        margin: 8px 0px;
    }

    .curr-dd {
        display: flex;
        justify-content: center;
        width: 100%;
        margin: 8px 10px 10px 10px;
    }
    .select-currency {
        color: grey;
        font-size: 14px;
        letter-spacing: 0.1px;
        font-family: vagRund;
    }

    .currency-button {
        width: 80px;
        border: 1px solid rgb(2, 2, 99) !important;
        border-radius: 36px !important; 
    }

    .currency-button:hover {
        color: rgb(2, 2, 99) !important;
        border: 1px solid rgb(2, 2, 99) !important;
        border-radius: 36px !important; 

    }

    .currency-button:active {
        background:#452872;
        color: white;
        border: 1px solid rgb(2, 2, 99) !important;
        border-radius: 36px !important; 
    }

    .currency-primary {
        width: 80px;
        background:#452872 !important;
        color: white !important;
        border: 1px solid rgb(2, 2, 99) !important;
        border-radius: 36px !important; 
        
    }
}
