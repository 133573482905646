@media screen and (min-width: 1030px) {
  .basic-card {
    height: 995px;
    width: 420px;
    border-radius: 12px !important;
    overflow: hidden;
    position: relative;
  }

  .basic-card:hover {
    box-shadow: 10px 10px 4px 0px rgba(148, 148, 148, 0.397);
    -webkit-box-shadow: 10px 10px 4px 0px rgba(173, 173, 173, 0.507);
    -moz-box-shadow: 10px 10px 4px 0px rgba(177, 177, 177, 0.39);
  }
  .basic-card.grow {
    transition: all 0.2s ease-in-out;
  }

  .basic-card:hover {
    transform: scale(1.05);
  }

  .card-div {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    width: 100%;
    margin-top: 40px;
  }

  .card-div-single {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 20px;
  }

  .card-title {
    color: grey;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0.2px;
    font-family: vagRund;
  }

  .divder-div {
    display: flex;
    width: 100%;
    justify-content: space-around;
  }
  .ocean {
    height: 5%;
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    background: #01597141;
    opacity: 0.7;
  }

  .wave {
    background: url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/85486/wave.svg)
      repeat-x;
    position: absolute;
    top: -168px;
    width: 6400px;
    height: 198px;
    animation: wave 7s cubic-bezier(0.36, 0.45, 0.63, 0.53) infinite;
    transform: translate3d(0, 0, 0);
    opacity: 0.1;
  }

  .wave:nth-of-type(2) {
    top: -125px;
    animation: wave 7s cubic-bezier(0.36, 0.45, 0.63, 0.53) -0.125s infinite,
      swell 7s ease -1.25s infinite;
    opacity: 0.2;
    transform: translate3d(0, -25px, 0);
    margin-left: -160px;
  }

  .oceantwo {
    height: 5%;
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    background: #85299741;
    opacity: 0.7;
  }

  .wavetwo {
    background: url(../../assets/wavePink2.png) repeat-x;
    position: absolute;
    top: -148px;
    width: 6400px;
    height: 198px;
    animation: wave 7s cubic-bezier(0.36, 0.45, 0.63, 0.53) infinite;
    transform: translate3d(0, 0, 0);
    opacity: 0.1;
  }

  .wavetwo:nth-of-type(2) {
    top: -125px;
    animation: wave 7s cubic-bezier(0.36, 0.45, 0.63, 0.53) -0.125s infinite,
      swell 7s ease -1.25s infinite;
    opacity: 0.2;
    transform: translate3d(0, -25px, 0);
    margin-left: -130px;
  }

  .oceanthird {
    height: 5%;
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    background: #53289941;
    opacity: 0.7;
  }

  .wavethird {
    background: url(../../assets/purple.png) repeat-x;
    position: absolute;
    top: -88px;
    width: 6400px;
    height: 198px;
    animation: wave 7s cubic-bezier(0.36, 0.45, 0.63, 0.53) infinite;
    transform: translate3d(0, 0, 0);
    opacity: 0.1;
  }

  .wavethird:nth-of-type(2) {
    top: -105px;
    animation: wave 7s cubic-bezier(0.36, 0.45, 0.63, 0.53) -0.125s infinite,
      swell 7s ease -1.25s infinite;
    opacity: 0.2;
    transform: translate3d(10, -25px, 0);
    margin-left: -50px;
  }

  .ocean4 {
    height: 5%;
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    background: #fdba0057;
    opacity: 0.7;
  }

  .wave4 {
    background: url(../../assets/yellow.jpeg) repeat-x;
    position: absolute;
    top: -135px;
    width: 6400px;
    height: 198px;
    animation: wave 7s cubic-bezier(0.36, 0.45, 0.63, 0.53) infinite;
    transform: translate3d(0, 0, 0);
    opacity: 0.1;
  }

  .wave4:nth-of-type(2) {
    top: -125px;
    animation: wave 7s cubic-bezier(0.36, 0.45, 0.63, 0.53) -0.125s infinite,
      swell 7s ease -1.25s infinite;
    opacity: 0.2;
    transform: translate3d(10, -25px, 0);
    margin-left: -50px;
  }

  .rate {
    font-size: 16px;
    color: #000;
    font-family: vagRund;
  }

  .time {
    font-size: 16px;
    color: grey;

    font-family: vagRund;
  }

  ul {
    list-style: none; /* Remove default bullets */
  }

  li {
    margin-top: 5px;
  }

  .features {
    margin: 30px 0px 10px 5px;
    text-align: left;
  }

  .button-checkout {
    width: 220px;
    padding: 12px 12px;
    border-radius: 32px;
    font-size: 16px;
    background: #452872;
    color: #fff;
    /* position: absolute; */
    /* top: 93%; */
    z-index: 10;
    /* right: 25%; */
    border: 0.01px solid rgba(29, 27, 110, 0.137);
  }

  .button-checkout:hover {
    background-color: #544adf96;
    color: #fff;
  }

  .button-checkout-disabled {
    background-color: rgb(190, 190, 190) !important;
    color: #fff !important;
    padding: 12px 42px;
    border-radius: 32px;
    font-size: 16px;

    /* position: absolute;
    top: 85%;
    z-index: 10;
    right: 16%; */
    border: 0.01px solid rgba(29, 27, 110, 0.137);
    width: 220px;
  }
}

@media screen and (min-width: 600px) and (max-width: 1030px) {
  .basic-card {
    height: 1300px;
    width: 420px;
    border-radius: 12px !important;
    overflow: hidden;
    position: relative;
    margin: 20px !important;
  }

  .basic-card:hover {
    box-shadow: 10px 10px 4px 0px rgba(148, 148, 148, 0.397);
    -webkit-box-shadow: 10px 10px 4px 0px rgba(173, 173, 173, 0.507);
    -moz-box-shadow: 10px 10px 4px 0px rgba(177, 177, 177, 0.39);
  }
  .basic-card.grow {
    transition: all 0.2s ease-in-out;
  }

  .basic-card:hover {
    transform: scale(1.05);
  }

  .card-div {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    width: 100%;
    margin-top: 20px;
    flex-wrap: wrap;
    flex-direction: column-reverse;
  }

  .card-div-single {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 20px;
  }

  .card-title {
    color: grey;
    font-size: 18px;
    font-weight: 400;
    letter-spacing: 0.2px;
    font-family: vagRund;
  }

  .divder-div {
    display: flex;
    width: 100%;
    justify-content: space-around;
  }
  .ocean {
    height: 5%;
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    background: #01597141;
    opacity: 0.7;
  }

  .wave {
    background: url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/85486/wave.svg)
      repeat-x;
    position: absolute;
    top: -168px;
    width: 6400px;
    height: 198px;
    animation: wave 7s cubic-bezier(0.36, 0.45, 0.63, 0.53) infinite;
    transform: translate3d(0, 0, 0);
    opacity: 0.1;
  }

  .wave:nth-of-type(2) {
    top: -125px;
    animation: wave 7s cubic-bezier(0.36, 0.45, 0.63, 0.53) -0.125s infinite,
      swell 7s ease -1.25s infinite;
    opacity: 0.2;
    transform: translate3d(0, -25px, 0);
    margin-left: -160px;
  }

  .oceantwo {
    height: 5%;
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    background: #85299741;
    opacity: 0.7;
  }

  .wavetwo {
    background: url(../../assets/wavePink2.png) repeat-x;
    position: absolute;
    top: -148px;
    width: 6400px;
    height: 198px;
    animation: wave 7s cubic-bezier(0.36, 0.45, 0.63, 0.53) infinite;
    transform: translate3d(0, 0, 0);
    opacity: 0.1;
  }

  .wavetwo:nth-of-type(2) {
    top: -125px;
    animation: wave 7s cubic-bezier(0.36, 0.45, 0.63, 0.53) -0.125s infinite,
      swell 7s ease -1.25s infinite;
    opacity: 0.2;
    transform: translate3d(0, -25px, 0);
    margin-left: -130px;
  }

  .oceanthird {
    height: 5%;
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    background: #53289941;
    opacity: 0.7;
  }

  .wavethird {
    background: url(../../assets/purple.png) repeat-x;
    position: absolute;
    top: -88px;
    width: 6400px;
    height: 198px;
    animation: wave 7s cubic-bezier(0.36, 0.45, 0.63, 0.53) infinite;
    transform: translate3d(0, 0, 0);
    opacity: 0.1;
  }

  .wavethird:nth-of-type(2) {
    top: -105px;
    animation: wave 7s cubic-bezier(0.36, 0.45, 0.63, 0.53) -0.125s infinite,
      swell 7s ease -1.25s infinite;
    opacity: 0.2;
    transform: translate3d(10, -25px, 0);
    margin-left: -50px;
  }

  .ocean4 {
    height: 5%;
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    background: #fdba0057;
    opacity: 0.7;
  }

  .wave4 {
    background: url(../../assets/yellow.jpeg) repeat-x;
    position: absolute;
    top: -135px;
    width: 6400px;
    height: 198px;
    animation: wave 7s cubic-bezier(0.36, 0.45, 0.63, 0.53) infinite;
    transform: translate3d(0, 0, 0);
    opacity: 0.1;
  }

  .wave4:nth-of-type(2) {
    top: -125px;
    animation: wave 7s cubic-bezier(0.36, 0.45, 0.63, 0.53) -0.125s infinite,
      swell 7s ease -1.25s infinite;
    opacity: 0.2;
    transform: translate3d(10, -25px, 0);
    margin-left: -50px;
  }

  .rate {
    font-size: 24px;
    color: #000;

    font-family: vagRund;
  }

  .time {
    font-size: 14px;
    color: grey;

    font-family: vagRund;
  }

  ul {
    list-style: none; /* Remove default bullets */
  }

  li {
    margin-top: 15px;
  }

  .features {
    margin: 30px 0px 10px 35px;
    text-align: left;
  }

  .button-checkout {
    padding: 12px 42px;
    border-radius: 32px;
    font-size: 15px;

    background: #452872;
    color: #fff;
    /* position: absolute; */
    /* top: 94%;
    z-index: 1;
    right: 28%; */
    box-shadow: 2px 2px 2px 2px rgba(102, 163, 255, 0.397);
  }

  .button-checkout:hover {
    background-color: #544adf96;
    color: #fff;
  }

  .button-checkout-disabled {
    background-color: rgb(190, 190, 190) !important;
    color: #fff !important;
    padding: 12px 42px;
    border-radius: 32px;
    font-size: 15px;

    position: absolute;
    top: 85%;
    z-index: 1;
    right: 22%;
    box-shadow: 2px 2px 2px 2px rgba(102, 163, 255, 0.397);
  }
}

@media screen and (max-width: 600px) {
  .basic-card {
    height: 1250px;
    width: 350px;
    border-radius: 12px !important;
    overflow: hidden;
    position: relative;
    margin: 18px 2px !important;
    padding: 10px 0px !important;
  }

  .basic-card:hover {
    box-shadow: 10px 10px 4px 0px rgba(148, 148, 148, 0.397);
    -webkit-box-shadow: 10px 10px 4px 0px rgba(173, 173, 173, 0.507);
    -moz-box-shadow: 10px 10px 4px 0px rgba(177, 177, 177, 0.39);
  }
  .basic-card.grow {
    transition: all 0.2s ease-in-out;
  }

  .basic-card:hover {
    transform: scale(1.05);
  }

  .card-div {
    display: block;
    align-items: center;
    justify-content: space-evenly;
    width: 100%;
    margin-top: 20px;
    flex-direction: column-reverse;
  }

  .card-div-single {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 20px;
  }

  .card-title {
    color: grey;
    font-size: 18px;
    font-weight: 400;
    letter-spacing: 0.2px;
    font-family: vagRund;
  }

  .divder-div {
    display: flex;
    width: 100%;
    justify-content: space-around;
  }
  .ocean {
    height: 5%;
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    background: #01597141;
    opacity: 0.7;
  }

  .wave {
    background: url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/85486/wave.svg)
      repeat-x;
    position: absolute;
    top: -168px;
    width: 6400px;
    height: 198px;
    animation: wave 7s cubic-bezier(0.36, 0.45, 0.63, 0.53) infinite;
    transform: translate3d(0, 0, 0);
    opacity: 0.1;
  }

  .wave:nth-of-type(2) {
    top: -125px;
    animation: wave 7s cubic-bezier(0.36, 0.45, 0.63, 0.53) -0.125s infinite,
      swell 7s ease -1.25s infinite;
    opacity: 0.2;
    transform: translate3d(0, -25px, 0);
    margin-left: -160px;
  }

  .oceantwo {
    height: 5%;
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    background: #85299741;
    opacity: 0.7;
  }

  .wavetwo {
    background: url(../../assets/wavePink2.png) repeat-x;
    position: absolute;
    top: -148px;
    width: 6400px;
    height: 198px;
    animation: wave 7s cubic-bezier(0.36, 0.45, 0.63, 0.53) infinite;
    transform: translate3d(0, 0, 0);
    opacity: 0.1;
  }

  .wavetwo:nth-of-type(2) {
    top: -125px;
    animation: wave 7s cubic-bezier(0.36, 0.45, 0.63, 0.53) -0.125s infinite,
      swell 7s ease -1.25s infinite;
    opacity: 0.2;
    transform: translate3d(0, -25px, 0);
    margin-left: -130px;
  }

  .oceanthird {
    height: 5%;
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    background: #53289941;
    opacity: 0.7;
  }

  .wavethird {
    background: url(../../assets/purple.png) repeat-x;
    position: absolute;
    top: -88px;
    width: 6400px;
    height: 198px;
    animation: wave 7s cubic-bezier(0.36, 0.45, 0.63, 0.53) infinite;
    transform: translate3d(0, 0, 0);
    opacity: 0.1;
  }

  .wavethird:nth-of-type(2) {
    top: -105px;
    animation: wave 7s cubic-bezier(0.36, 0.45, 0.63, 0.53) -0.125s infinite,
      swell 7s ease -1.25s infinite;
    opacity: 0.2;
    transform: translate3d(10, -25px, 0);
    margin-left: -50px;
  }

  .ocean4 {
    height: 5%;
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    background: #fdba0057;
    opacity: 0.7;
  }

  .wave4 {
    background: url(../../assets/yellow.jpeg) repeat-x;
    position: absolute;
    top: -135px;
    width: 6400px;
    height: 198px;
    animation: wave 7s cubic-bezier(0.36, 0.45, 0.63, 0.53) infinite;
    transform: translate3d(0, 0, 0);
    opacity: 0.1;
  }

  .wave4:nth-of-type(2) {
    top: -125px;
    animation: wave 7s cubic-bezier(0.36, 0.45, 0.63, 0.53) -0.125s infinite,
      swell 7s ease -1.25s infinite;
    opacity: 0.2;
    transform: translate3d(10, -25px, 0);
    margin-left: -50px;
  }

  .rate {
    font-size: 24px;
    color: #000;

    font-family: vagRund;
  }

  .time {
    font-size: 14px;
    color: grey;

    font-family: vagRund;
  }

  ul {
    list-style: none; /* Remove default bullets */
  }

  li {
    margin-top: 15px;
  }

  .features {
    margin: 10px 0px 5px 10px;
    text-align: left;
  }

  .button-checkout {
    padding: 12px 42px;
    border-radius: 32px;
    font-size: 15px;

    background: #452872;
    color: #fff;
    position: absolute;
    top: 95%;
    z-index: 1;
    right: 26%;
    box-shadow: 2px 2px 2px 2px rgba(102, 163, 255, 0.397);
  }

  .button-checkout:hover {
    background-color: #544adf96;
    color: #fff;
  }

  .button-checkout-disabled {
    background-color: rgb(190, 190, 190) !important;
    color: #fff !important;
    padding: 12px 42px;
    border-radius: 32px;
    font-size: 15px;

    position: absolute;
    top: 85%;
    z-index: 1;
    right: 22%;
    box-shadow: 2px 2px 2px 2px rgba(102, 163, 255, 0.397);
  }
}
