body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #252857;
  font-family: vagRund;
  background-color: #f0f0f0 !important;

}
@font-face {
  font-family: 'vagRund';
  src: local('vagRund'), url(./fonts/VAG_Rounded_Light.ttf) format('truetype');
}

