@media screen and (min-width: 1030px) {
  .parent-div {
    font-family: vagRund;
    color: black;
    margin: 40px 320px;
  }

  .ins {
    display: flex;
    flex-direction: column;
    margin: 20px 0;
    text-align: center;
  }

  span {
    font-size: 18px;
  }

  .script-link {
    width: auto;
    align-items: center;
    text-align: center;
    display: flex;
    justify-content: center;
    background-color: #1d1b2d;
    border-radius: 12px;
  }

  .inner-div {
    background-color: #1d1b2d;
    width: auto;
    padding: 30px;
  }

  .button-div {
    display: flex;
    justify-content: flex-end;
  }

  .button-copy {
    border-radius: 22px !important;
    width: 100px;
    height: 40px !important;
  }

  .innest-div {
    margin-top: 15px;
    background-color: black;
    padding: 20px;
  }

  .ant-collapse .ant-collapse-item .ant-collapse-header {
    position: relative;
    padding: 12px 16px;
    padding-left: 40px;
    color: #0000ee !important;
    line-height: 1.1715 !important;
    cursor: pointer;
    -webkit-transition: all 0.3s, visibility 0s;
    transition: all 0.3s, visibility 0s;
    font-size: 18px !important;
  }

  .padd {
    margin: 10px 80px !important;
  }

  .wix-wp-ins {
    text-align: center;
  }
  .wp-div {
    width: 100%;
    display: flex;
    justify-content: center;
    margin: 10px;
  }

  .wp {
    width: 120px;
    height: 125px;
    cursor: pointer;
  }

  .wx {
    width: 120px;
    height: 125px;
    cursor: pointer;
  }

  .mini-div {
    margin: 10px 0px;
    background-color: #fff;
  }
}

@media screen and (min-width: 600px) and (max-width: 1030px) {
  .parent-div {
    font-family: vagRund;
    color: black;
    margin: 40px 320px;
  }

  .ins {
    display: flex;
    flex-direction: column;
    margin: 20px 0;
    text-align: center;
  }

  span {
    font-size: 18px;
  }

  .script-link {
    width: auto;
    align-items: center;
    text-align: center;
    display: flex;
    justify-content: center;
    background-color: #1d1b2d;
    border-radius: 12px;
  }

  .inner-div {
    background-color: #1d1b2d;
    width: auto;
    padding: 30px;
  }

  .button-div {
    display: flex;
    justify-content: flex-end;
  }

  .button-copy {
    border-radius: 22px !important;
    width: 100px;
    height: 40px !important;
  }

  .innest-div {
    margin-top: 15px;
    background-color: black;
    padding: 20px;
  }

  .ant-collapse .ant-collapse-item .ant-collapse-header {
    position: relative;
    padding: 12px 16px;
    padding-left: 40px;
    color: #0000ee !important;
    line-height: 1.1715 !important;
    cursor: pointer;
    -webkit-transition: all 0.3s, visibility 0s;
    transition: all 0.3s, visibility 0s;
    font-size: 18px !important;
  }

  .padd {
    margin: 10px 80px !important;
  }

  .wix-wp-ins {
    text-align: center;
  }
  .wp-div {
    width: 100%;
    display: flex;
    justify-content: center;
    margin: 10px;
  }

  .wp {
    width: 120px;
    height: 125px;
    cursor: pointer;
  }

  .wx {
    width: 120px;
    height: 125px;
    cursor: pointer;
  }

  .mini-div {
    margin: 10px 0px;
    background-color: #fff;
  }
}

@media screen and (max-width: 600px) {
  .parent-div {
    font-family: vagRund;
    color: black;
    margin: 10px 0px;
  }

  .ins {
    display: flex;
    flex-direction: column;
    margin: 10px 0;
    text-align: center;
  }

  span {
    font-size: 18px;
  }

  .script-link {
    width: 100%;
    align-items: center;
    text-align: center;
    display: flex;
    justify-content: center;
    background-color: #1d1b2d;
    border-radius: 12px;
  }

  .inner-div {
    background-color: #1d1b2d;
    width: 100%;
    padding: 10px;
  }

  .button-div {
    display: flex;
    justify-content: flex-end;
  }

  .button-copy {
    border-radius: 22px !important;
    width: 80px;
    height: 35px !important;
    font-size: 10px !important;
  }

  .sc {
    width: 100%;
  }

  .innest-div {
    margin-top: 5px;
    background-color: black;
    padding: 10px;
  }

  .ant-collapse .ant-collapse-item .ant-collapse-header {
    position: relative;
    padding: 12px 16px;
    padding-left: 40px;
    color: #0000ee !important;
    line-height: 1.1715 !important;
    cursor: pointer;
    -webkit-transition: all 0.3s, visibility 0s;
    transition: all 0.3s, visibility 0s;
    font-size: 18px !important;
  }

  .padd {
    margin: 10px 80px !important;
  }

  .wix-wp-ins {
    text-align: center;
  }
  .wp-div {
    width: 100%;
    display: flex;
    justify-content: center;
    margin: 10px;
  }

  .wp {
    width: 120px;
    height: 125px;
    cursor: pointer;
  }

  .wx {
    width: 120px;
    height: 125px;
    cursor: pointer;
  }

  .mini-div {
    margin: 10px 0px;
    background-color: #fff;
  }
}
