@media screen and (min-width: 1030px) {
    .land-setup {
        display: flex !important;
        flex-direction: column !important;
        justify-content: center !important;
        width: 100% !important;
        align-items: center;
       
    }
  
    
    .land-links {
        display: flex;
        justify-content: space-between;

        width: 100%;
    }
    
    .land-card {
        width: 600px;
        background-color: #f8f8f8;
        padding: 24px 0px;
        border: 1px double #cacaca;
        border-radius: 8px;
        margin: 10% 0px;
    }

    .button-primary {
        width: 180px;
        height: 50px;
        color: white;
        background: #252857;
    }

    .button-primary:hover {
        border: 1px solid #252857
    }
    
}

@media screen and (min-width: 600px) and (max-width: 1030px) {
    .land-setup {
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100vh;
        width: 100%;
        align-items: center;
        background: rgb(46, 46, 46);

    }
    
 
    .land-links {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        width: 100%;
    }
    
    .land-card {
        width: 700px;
        background-color: #f8f8f8;
        padding: 24px 0px;
        border: 1px double #cacaca;
        border-radius: 8px;
    }

    .button-primary {
        width: 160px;
        height: 50px;
        color: white;
        background: #252857;
    }

    .button-primary:hover {
        border: 1px solid #252857
    }
    
}

@media screen and (max-width: 600px) {
    .land-setup {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        overflow: hidden;
        height: 100vh;
    }
    
   
    .land-links {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        width: 100%;
    }
    
    .land-card {
        width: auto;
        background-color: #f8f8f8;
        padding: 24px 10px;
        border: 1px double #cacaca;
        border-radius: 8px;
    }


    .button-primary {
        width: 160px;
        height: 50px;
        color: white;
        background: #252857;
    }

    .button-primary:hover {
        border: 1px solid #252857
    }
    
}