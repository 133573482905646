
@media screen and (min-width: 1030px) {
    .form-setup {
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100vh;
        width: 100%;
        align-items: center;
        background: rgb(46, 46, 46);

    }
    
    .signup-link {
        display: flex;
        justify-content: center;
    }
    
    .div-links {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        width: 100%;
    }
    
    .sign-in-card {
        width: 700px;
        background-color: #f8f8f8;
        padding: 24px 0px;
        border: 1px double #cacaca;
        border-radius: 8px;
    }
    
}

@media screen and (min-width: 600px) and (max-width: 1030px) {
    .form-setup {
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100vh;
        width: 100%;
        align-items: center;
        background: rgb(46, 46, 46);

    }
    
    .signup-link {
        display: flex;
        justify-content: center;
    }
    
    .div-links {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        width: 100%;
    }
    
    .sign-in-card {
        width: 700px;
        background-color: #f8f8f8;
        padding: 24px 0px;
        border: 1px double #cacaca;
        border-radius: 8px;
    }
    
}

@media screen and (max-width: 600px) {
    .form-setup {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background: rgb(58, 58, 58);
        width: 100%;
        overflow: hidden;
        height: 100vh;
    }
    
    .signup-link {
        display: flex;
        justify-content: center;
    }
    
    .div-links {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        width: 100%;
    }
    
    .sign-in-card {
        width: auto;
        background-color: #f8f8f8;
        padding: 24px 10px;
        border: 1px double #cacaca;
        border-radius: 8px;
    }
    
}