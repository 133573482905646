@import "~antd/dist/antd.css";
@media screen and (min-width: 1030px) {
  .App {
    text-align: center;
    font-family: vagRund;

  }

  .App-header {
    min-height: 100vh;
    font-size: calc(10px + 2vmin);
    color: transparent;
    font-family: vagRund;
  }

  #components-layout-demo-fixed .logo {
    float: left;
    width: 120px;
    height: 31px;
    margin: 16px 24px 16px 0;
  }

  .site-layout-background {
    background: transparent;
    margin: 50px 40px 20px 40px !important;
    padding: 10px !important;
    width: -webkit-fill-available !important;
    overflow-x: hidden !important;
  }

  .footer {
    background-color: black;
    height: 50px;
    padding-bottom: 50px;
  }

  .footer-div {
    display: flex;
    justify-self: center;
    align-items: center;
    align-self: center;
  }

  .footer-text {
    color: #fff;
    text-align: center;
    width: 100%;
  }

  .primary-button {
    width: 150px;
    background-color: #452872;
    border-radius: 22px;
    align-self: center;
    height: 40px;
    margin-top: 20px;
  }

  .StripeElement {
    margin: 15px auto;
    padding: 10px 12px;
    color: #32325d;
    background-color: rgb(245, 245, 245);
    border: 1px solid transparent;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .StripeElement--webkit-autofill {
    background-color: rgb(245, 245, 245) !important;
  }

  .product {
    width: 100%;
    max-width: 450px;
    margin: auto;
    box-shadow: 0px 15px 30px rgba(0, 0, 0, 0.4);
    border-radius: 10px;
    overflow: hidden;
  }

  .btn-pay {
    display: block;
    width: 100%;
    border: none;
    background: linear-gradient(
      135deg,
      rgb(49, 0, 62) 0%,
      rgb(195, 40, 110) 100%
    );
    color: #fff;
    padding: 10px;
    font-size: 18px;
    cursor: pointer;
  }

  .product-info {
    padding: 0 16px;
  }

  h3.product-title {
    font-size: 28px;
    margin-bottom: 15px;
  }

  h4.product-price {
    font-size: 24px;
    margin: 0;
    margin-bottom: 30px;
    color: #777;
  }

  .primary-button {
    background: #252857;
    color: #fff;
    font-size: 16px;
    font-family: inherit;
    border: none;
    border-radius: 4px;
    padding: 8px 20px;
    display: block;
    width: 100%;
    letter-spacing: 0.5px;
    transition: 0.2s;
    cursor: pointer;
    margin: 20px;
  }

  .primary-button:hover,
  .primary-button:focus {
    background: #191c4a83;
    color: #fff;
  }

  .div-button {
    display: flex;
    width: 100%;
    justify-content: space-around;
  }
  .link-style {
    color: rgb(23, 23, 58);
    margin: 20px;
  }
}

@media screen and (min-width: 600px) and (max-width: 1030px) {
  .App {
    text-align: center;
    font-family: vagRund;

  }

  .App-header {
    min-height: 100vh;
    font-size: calc(10px + 2vmin);
    color: white;
    font-family: vagRund;

  }

  #components-layout-demo-fixed .logo {
    float: left;
    width: 120px;
    height: 31px;
    margin: 16px 24px 16px 0;
  }

  .site-layout-background {
    background: #fff;
    margin: 100px 20px 20px 20px !important;
    width: -webkit-fill-available !important;
    overflow-x: hidden !important;
  }

  .footer {
    background-color: black;
    height: 50px;
    padding-bottom: 50px;
  }

  .footer-div {
    display: flex;
    justify-self: center;
    align-items: center;
    align-self: center;
  }

  .footer-text {
    color: #fff;
    text-align: center;
    width: 100%;
  }

  .primary-button {
    width: 150px;
    background-color: #452872;
    border-radius: 22px;
    align-self: center;
    height: 40px;
    margin-top: 20px;
  }

  .StripeElement {
    margin: 15px auto;
    padding: 10px 12px;
    color: #32325d;
    background-color: rgb(245, 245, 245);
    border: 1px solid transparent;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .StripeElement--webkit-autofill {
    background-color: rgb(245, 245, 245) !important;
  }

  .product {
    width: 100%;
    max-width: 450px;
    margin: auto;
    box-shadow: 0px 15px 30px rgba(0, 0, 0, 0.4);
    border-radius: 10px;
    overflow: hidden;
  }

  .btn-pay {
    display: block;
    width: 100%;
    border: none;
    background: linear-gradient(
      135deg,
      rgb(49, 0, 62) 0%,
      rgb(195, 40, 110) 100%
    );
    color: #fff;
    padding: 10px;
    font-size: 18px;
    cursor: pointer;
  }

  .product-info {
    padding: 0 16px;
  }

  h3.product-title {
    font-size: 28px;
    margin-bottom: 15px;
  }

  h4.product-price {
    font-size: 24px;
    margin: 0;
    margin-bottom: 30px;
    color: #777;
  }
  .primary-button {
    background: #252857;
    color: #fff;
    font-size: 16px;
    font-family: inherit;
    border: none;
    border-radius: 4px;
    padding: 8px 10px;
    display: block;
    width: 100%;
    letter-spacing: 0.5px;
    transition: 0.2s;
    cursor: pointer;
    margin: 20px;
  }

  .primary-button:hover,
  .primary-button:focus {
    background: #191c4a83;
    color: #fff;
  }
}

@media screen and (max-width: 600px) {
  .App {
    text-align: center;
    width: 100%;
    font-family: vagRund;

  }

  .App-header {
    min-height: 100vh;
    font-size: calc(10px + 2vmin);
    color: white;
    width: 100%;
    font-family: vagRund;

  }

  #components-layout-demo-fixed .logo {
    float: left;
    /* width: 120px; */
    height: 31px;
    margin: 16px 24px 16px 0;
  }

  .site-layout-background {
    background: #fff;
    margin: 100px 10px 20px 10px !important;
    width: -webkit-fill-available !important;
    overflow-x: hidden !important;
  }

  .footer {
    background-color: black;
    height: 50px;
    padding-bottom: 50px;
  }

  .footer-div {
    display: flex;
    justify-self: center;
    align-items: center;
    align-self: center;
  }

  .footer-text {
    color: #fff;
    text-align: center;
    width: 100%;
  }

  .primary-button {
    width: 150px;
    background-color: #452872;
    border-radius: 22px;
    align-self: center;
    height: 40px;
    margin-top: 20px;
  }

  .StripeElement {
    margin: 15px auto;
    padding: 10px 12px;
    color: #32325d;
    background-color: rgb(245, 245, 245);
    border: 1px solid transparent;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .StripeElement--webkit-autofill {
    background-color: rgb(245, 245, 245) !important;
  }

  .product {
    width: 100%;
    max-width: 450px;
    margin: auto;
    box-shadow: 0px 15px 30px rgba(0, 0, 0, 0.4);
    border-radius: 10px;
    overflow: hidden;
  }

  .btn-pay {
    display: block;
    width: 100%;
    border: none;
    background: linear-gradient(
      135deg,
      rgb(49, 0, 62) 0%,
      rgb(195, 40, 110) 100%
    );
    color: #fff;
    padding: 10px;
    font-size: 18px;
    cursor: pointer;
  }

  .product-info {
    padding: 0 16px;
  }

  h3.product-title {
    font-size: 28px;
    margin-bottom: 15px;
  }

  h4.product-price {
    font-size: 24px;
    margin: 0;
    margin-bottom: 30px;
    color: #777;
  }

  .primary-button {
    background: #252857;
    color: #fff;
    font-size: 16px;
    font-family: inherit;
    border: none;
    border-radius: 4px;
    padding: 4px 10px;
    display: block;
    width: 100%;
    letter-spacing: 0.5px;
    transition: 0.2s;
    cursor: pointer;
    margin: 20px;
  }

  .primary-button:hover,
  .primary-button:focus {
    background: #191c4a83;
    color: #fff;
  }

  .logo {
    display: none;
  }
}

.button-checkout:disabled {
  background-color: rgb(190, 190, 190) !important;
  color: #fff !important;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  position: relative;
  background-color: #eff2f5 !important;
  border: none !important;
  border-radius: 30px !important;
  -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.ant-select-selection-search-input {
  border: none !important;
  border-color: #452872 !important;
}

.ant-select {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #452872 !important;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  -webkit-font-feature-settings: "tnum";
  font-feature-settings: "tnum", "tnum";
  position: relative;
  display: inline-block;
  cursor: pointer;
  border: none !important;
  border-color: #452872 !important;

}

.ant-select .ant-select-single .ant-select-show-arrow {
  border: none !important;
}
.ant-select-item-option-selected:not(.ant-select-item-option-disabled)
  .ant-select-item-option-state {
  color: #452872 !important;
  background-color: #46287267 !important;
  border: transparent !important;
}

.ant-select.ant-select-focused .ant-select-single .ant-select-show-arrow {
  border: none !important;
}

.ant-select-item-option-selected {
  color: #452872 !important;
  background-color: #4628723f !important;
  border: none !important;
}
.ant-select-selection-item {
  color: #452872 !important;
  border: none !important;
}

.ant-select-item-option-active {
  color: #452872 !important;
  background-color: #4628723f !important;
}


.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-color: #452872 !important;

}