@media screen and (min-width: 1030px) {
  .parent-div {
    font-family: vagRund;
    color: black;
    margin: 40px 100px;
    padding: 80px;
    background-color: #fff;
  }

  .header-div {
    display: flex;
    width: 100%;
    justify-content: space-evenly;
    align-items: center;
  }

  .inner-hed-div {
    display: flex;
    justify-content: center;
    font-size: 22px;
    margin-top: 20px;
  }

  h3 {
    font-family: vagRund !important;
    background-color: #a42083;
    color: #fff;
    width: 40px;
    border-radius: 50%;

    height: 40px;
    padding-left: 15px;
    font-size: 24px;
  }

  .hedingss {
    display: flex;
    width: 100%;
    justify-content: center;
    margin-top: 20px;
  }

  span {
    font-family: vagRund !important;
    font-size: 18px;
  }

  .step2 {
    width: 800px;
  }

  .step1 {
    width: 250px;
  }

  .step3 {
    width: 500px;
  }

  .step4 {
    width: 65%;
  }

  .step5 {
    width: 65%;
  }

  .steps {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
  }

  .step-img-text {
    display: flex;
    margin-top: 30px;
    align-items: center;
    height: 100%;
    width: 100%;
  }

  .step-text {
    font-size: 20px;
    padding: 20px;
  }

  .num-img {
    display: flex;
  }
}

@media screen and (min-width: 600px) and (max-width: 1030px) {
  .parent-div {
    font-family: vagRund;
    color: black;
    margin: 40px 10px;
    padding: 10px;
    background-color: #fff;
  }
  .inner-hed-div {
    display: block;
  }

  .header-div {
    display: flex;
    width: 90%;
    justify-content: space-between;
    align-items: flex-start;
  }

  h3 {
    font-family: vagRund !important;
    background-color: #a42083;
    color: #fff;
    width: 35px;
    border-radius: 50%;
    font-weight: 400;
    height: 35px;
    padding-left: 12px;
    font-size: 24px;
  }

  .hedingss {
    display: flex;
    width: 100%;
    justify-content: center;
    margin-top: 20px;
  }

  span {
    font-family: vagRund !important;
    font-size: 18px;
  }

  .step2 {
    width: 350px;
  }

  .step1 {
    width: 250px;
  }

  .step3 {
    width: 350px;
    margin-top: 10px;
  }

  .step4 {
    width: 50%;
    margin-top: 10px;
  }

  .step5 {
    width: 50%;
    margin-top: 10px;
  }

  .steps {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
  }

  .step-img-text {
    display: flex;
    margin-top: 30px;
    align-items: center;
    height: 100%;
    width: 100%;
  }

  .step-text {
    font-size: 20px;
    padding: 20px;
  }

  .num-img {
    display: flex;
  }
}

@media screen and (max-width: 600px) {
  .parent-div {
    font-family: vagRund;
    color: black;
    margin: 10px 0px;
    padding: 10px;
    background-color: #fff;
  }

  .header-div {
    display: block;
    width: 90%;
    justify-content: space-between;
    align-items: center;
    text-align: center;
  }
  .inner-hed-div {
    display: block;
  }

  h3 {
    font-family: vagRund !important;
  }

  .hedingss {
    display: flex;
    width: 100%;
    justify-content: center;
    margin-top: 20px;
  }

  span {
    font-family: vagRund !important;
    font-size: 18px;
  }

  .step2 {
    width: 280px;
    margin-top: 20px;
  }

  .step1 {
    width: 180px;
    margin-top: 20px;
  }

  .step3 {
    width: 280px;
    margin-top: 20px;
  }

  .step4 {
    width: 80%;
    margin-top: 20px;
  }

  .step5 {
    width: 80%;
    margin-top: 20px;
  }

  .steps {
    display: block;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
  }

  h3 {
    font-family: vagRund !important;
    background-color: #a42083;
    color: #fff;
    width: 30px;
    border-radius: 50%;
    font-weight: 400;
    height: 30px;
    padding-left: 10px;
    font-size: 20px;
  }

  .logo-wx {
    width: 20%;
  }

  .step-img-text {
    display: block;
    margin-top: 30px;
    align-items: center;
    height: 100%;
    width: 100%;
  }

  .step-text {
    font-size: 20px;
    padding: 20px;
  }

  .num-img {
    display: block;
  }
}
